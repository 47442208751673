<script>
import Config from '@/config'

export default {
  name: 'ViewSettings',

  data() {
    return {
      showPrivacy: false,
      showTerms: false
    }
  },

  computed: {
    aboutEntries() {
      return [
        {
          slug: 'version',
          label: this.$t('conn3ct-wallet.settings_version_label'),
          value: `v. ${Config.version.client}`
        }
      ]
    },

    menuItems() {
      return [
        {
          slug: 'pin',
          label: this.$user.user?.pinSet ? this.$t('conn3ct-wallet.settings_pin_button') : this.$t('conn3ct-wallet.settings_new_pin_button'),
          route: { name: 'sayl-connect_user-pin' }
        },

        {
          slug: 'key',
          label: this.$t('conn3ct-wallet.settings_download_key_button'),
          route: { name: 'sayl-connect_user-key' }
        },

        {
          slug: 'wallets',
          label: this.$t('conn3ct-wallet.settings_manage_external_wallet_button'),
          route: { name: 'sayl-connect_user-external-wallets' }
        }
      ]
    }
  },

  methods: {
    closeFrame() {
      this.frameUrl = null
    },

    onLogout() {
      this.$confirm({
        description: this.$t('conn3ct-wallet.logout_description'),
        onPrimary: () => this.$router.push({ name: 'logout' }).catch(() => {}),
        primaryAction: this.$t('conn3ct-wallet.settings_logout'),
      })
    },

    openPrivacy() {
      this.showPrivacy = true
    }
  },

  mounted() {
    this.$user.getUser()
    this.$user.getTerms()
    window.scrollTo(0, 0)
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-user settings"
    :title="$t('conn3ct-wallet.settings_title')"
  >
    <div class="settings__content">
      <section class="settings__actions">
        <router-link
          v-for="(item, i) in menuItems"
          class="settings__action"
          :key="`${item.slug}_${i}`"
          :to="item.route"
        >
          <div class="settings__action-label">
            <span>{{ item.label }}</span>

            <div class="settings__action-icon">
              <icon-arrow orientation="right" />
            </div>
          </div>
        </router-link>
      </section>

      <section class="settings__manage">
        <div class="settings__about">
          <h4 class="settings__about-title">{{ $t('conn3ct-wallet.settings_about_title') }}</h4>

          <p
            v-for="(item, i) in aboutEntries"
            class="settings__about-entry"
            :key="`${item.slug}_${i}`"
          >
            <span class="settings__about-label">{{ item.label }}</span>
            <span class="settings__about-value">{{ item.value }}</span>
          </p>
        </div>

        <div class="settings__manage-actions">
          <actions-button
            @click="showTerms = true"
            class="settings__manage-action"
          >{{ $t('conn3ct-wallet.show_terms') }}</actions-button>

          <actions-button
            @click="showPrivacy = true"
            class="settings__manage-action"
          >{{ $t('conn3ct-wallet.show_privacy') }}</actions-button>

          <actions-button
            @click="onLogout"
            :appearance="$pepper.Appearance.PRIMARY"
            class="settings__manage-action logout"
          >
            <div class="settings__manage-action-label">
              <span>{{ $t('conn3ct-wallet.settings_logout') }}</span>
              <icon-logout />
            </div>
          </actions-button>
        </div>
      </section>
    </div>

    <popins-modal
      @close="showPrivacy = false"
      :visible="showPrivacy"
    >
      <iframe
        src="https://www.saylcloud.com/privacy-policy"
        class="settings__iframe"
      ></iframe>
    </popins-modal>

    <popins-modal
      @close="showTerms = false"
      :visible="showTerms"
    >
      <div
        v-html="this.$user.terms"
        class="settings__terms"
      ></div>
    </popins-modal>
  </layout-page>
</template>
